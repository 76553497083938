import React, { ReactElement, useState, useEffect } from "react";
import Modal from "@components/shared/modal";
import CloseIcon from "@components/svg/closeIcon";
import { useDomainContext } from "@hooks/useDomainContext";

export default function TCPA({
    content,
    className,
    buttonText,
    position,
}: {
    content: string | { TCPAMessage: string }[];
    className: string;
    buttonText?: string;
    position?: "aboveSubmitButton" | "bottom";
}): ReactElement {
    const { domain } = useDomainContext();
    const [showPartnerPopup, setShowPartnerPopup] = useState(false);
    const [finalPopupContent, setFinalPopupContent] = useState<string | null>(
        null,
    );
    const extractTcpaMessagesFromContent = (arr: { TCPAMessage: string }[]) => {
        const arrayOfStrings = arr.map((el) => el.TCPAMessage);
        const tcpaArrayAfterRemoveDuplicate = new Set(arrayOfStrings);
        return [...tcpaArrayAfterRemoveDuplicate];
    };
    useEffect(() => {
        const partnersEl = document.getElementById("tcpa-Modal");
        const popupContent = partnersEl?.getElementsByTagName("span")[0];
        if (popupContent) {
            setFinalPopupContent(popupContent?.innerHTML.trim());
        }

        partnersEl?.addEventListener("click", () => {
            setShowPartnerPopup(true);
        });
    }, []);

    const btnText = "data-tf-element-role='submit-text'";

    return (
        <>
            <input type="hidden" id="leadid_tcpa_disclosure"></input>
            <label
                htmlFor="leadid_tcpa_disclosure"
                style={{
                    height: "fit-content",
                    fontSize: "9px",
                    color: "#242626",
                }}
                {...(domain.name === "eliteinjuryadvocates.com"
                    ? { "data-tf-element-role": "consent-language" }
                    : {})}
                className={`font-light leading-3 block mt-5 text-justify mb-5 ${
                    className ?? ""
                }`}
            >
                {Array.isArray(content) && content.length ? (
                    <>
                        {extractTcpaMessagesFromContent(content).map(
                            (tcpa, index) => (
                                <div
                                    key={index}
                                    className=" mt-5"
                                    dangerouslySetInnerHTML={{
                                        __html: tcpa?.replace(
                                            /\$\{btnText\}/g,
                                            buttonText
                                                ? `\<span ${
                                                      domain.name ===
                                                      "eliteinjuryadvocates.com"
                                                          ? btnText
                                                          : ""
                                                  } \>
                                                    ${buttonText} ${
                                                      domain.name ===
                                                      "eliteinjuryadvocates.com"
                                                          ? `<strong>
                                                              <span data-tf-element-role="consent-advertiser-name">
                                                                  Walker
                                                              </span>
                                                          </strong>`
                                                          : ""
                                                  }
                                                \<\/span\>`
                                                : `\<span ${
                                                      domain.name ===
                                                      "eliteinjuryadvocates.com"
                                                          ? btnText
                                                          : ""
                                                  }\>
                                                    submit ${
                                                        domain.name ===
                                                        "eliteinjuryadvocates.com"
                                                            ? `<strong>
                                                                <span data-tf-element-role="consent-advertiser-name">
                                                                    Walker
                                                                </span>
                                                            </strong>`
                                                            : ""
                                                    }
                                                \<\/span\>`,
                                        ),
                                    }}
                                />
                            ),
                        )}
                    </>
                ) : (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: (content as string)
                                .replace(
                                    "button above",
                                    position === "aboveSubmitButton"
                                        ? "button below"
                                        : "button above",
                                )
                                ?.replace(
                                    /\$\{btnText\}/g,
                                    buttonText
                                        ? `\<span ${
                                              domain.name ===
                                              "eliteinjuryadvocates.com"
                                                  ? btnText
                                                  : ""
                                          } \>
                                    ${buttonText} ${
                                              domain.name ===
                                              "eliteinjuryadvocates.com"
                                                  ? `<strong>
                                                  <span data-tf-element-role="consent-advertiser-name">
                                                      Walker
                                                  </span>
                                              </strong>`
                                                  : ""
                                          }
                                \<\/span\>`
                                        : `\<span ${
                                              domain.name ===
                                              "eliteinjuryadvocates.com"
                                                  ? btnText
                                                  : ""
                                          }\>
                                    submit ${
                                        domain.name ===
                                        "eliteinjuryadvocates.com"
                                            ? `<strong>
                                                <span data-tf-element-role="consent-advertiser-name">
                                                    Walker
                                                </span>
                                            </strong>`
                                            : ""
                                    }
                                \<\/span\>`,
                                ),
                        }}
                    />
                )}
            </label>
            <Modal
                onClose={() => {
                    setShowPartnerPopup(false);
                }}
                active={showPartnerPopup && !!finalPopupContent}
                closeIcon={<CloseIcon />}
                backdropClosable
                modalStyles={{
                    display: "flex",
                    flexDirection: "column",
                    maxHeight: "100%",
                }}
                modalClassName="h-[90vh] rounded"
                bodyStyles={{ overflowY: "scroll" }}
                width={900}
                header="Our Partners"
            >
                <div
                    className="pb-5 lg:px-9 px-5"
                    dangerouslySetInnerHTML={{
                        __html: finalPopupContent as string,
                    }}
                />
            </Modal>
        </>
    );
}
